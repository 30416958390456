import { Card, Image, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';
import classes from './../../styles/ProductCard.module.css';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { IconShoppingCart, IconStarFilled } from '@tabler/icons-react';
import useStore from '../../store/useStore';
import { LazyLoadImage } from 'react-lazy-load-image-component';


interface Props {
  id: string;
  title: string;
  image: string;
  price: number;
  priceAfterDiscount: number;
  rating?: number;
}

export function ProductCard({ id, image, title, price, priceAfterDiscount, rating }: Props) {
    const matches = useMediaQuery('(max-width: 36em)');
    const dataStore = useStore((state: any) => state.store);

    console.log(image);
    

    return (
        <Card withBorder radius={"xs"} p="md" className={classes.card} h={"100%"}>
            <Card.Section style={{position: 'relative'}} className={classes.firstSection}>
                {/* <Image src={`${process.env.REACT_APP_API_URL_IMAGES}/${image}`} alt={title} w={"100%"} h={"100%"} pos={'absolute'} fit={'fill'}/> */}
                <LazyLoadImage
                    src={`${process.env.REACT_APP_API_URL_IMAGES}/${image}.webp`}
                    alt={""}
                    height={"100%"}
                    width={"100%"}
                    delayTime={0}
                    style={{position: "absolute", objectFit: "fill"}}
                    placeholderSrc={`${process.env.REACT_APP_API_URL_THUMBNAILS}/${image}.webp`}
                />
                {priceAfterDiscount > 0
                    ? <Badge
                        variant="filled" color={dataStore?.information?.backgroundColor || "#48a172"}
                        c={dataStore?.information?.textColor || "#fff"}
                        size='lg' className={classes.badge} radius={0}
                    >
                        تخفيض
                    </Badge>
                    : null
                }

                {rating && rating > 0
                    ? <Badge variant="filled" color={"#fff"}  size='md' className={classes.rating} radius={10}>
                        <Group gap={3} justify='center' align='center'>
                            <IconStarFilled size={16} style={{color: "#f7ce2a"}} />
                            <Text size={"xs"} c={"#121212"} > {rating} </Text>
                        </Group>
                    </Badge>
                    : null
                }
            </Card.Section>
        
            <Card.Section className={classes.section} mt="sm" mb={0} display={'flex'} flex={"auto"} style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <Text size={matches ? "sm" : "md"} fw={600} component={Link} to={`/product/${id}`} className={classes.link}>
                    {title}
                </Text>

                <Group gap={10} justify='center' dir='rtl'>
                    <Text size={matches ? "xs" : "sm"} fw={700} className={classes.label} c="#3e9667">
                        {priceAfterDiscount > 0 ? priceAfterDiscount : price} د.ج
                    </Text>
                    {priceAfterDiscount > 0 
                        ? <Text size={matches ? "xs" : "sm"} fw={400} className={classes.label} c="gray.5" td="line-through">
                            {price} د.ج
                        </Text>
                        : null
                    }
                </Group>
                <Group flex={"auto"} mt={"xs"} align='flex-end' w={"100%"}>
                    <Button 
                        variant='filled' size='md' radius={"sm"} style={{ flex: 1 }}
                        color={dataStore?.information?.backgroundColor || "#48a172"}
                        c={dataStore?.information?.textColor || "#fff"}
                        component={Link} to={`/product/${id}`}
                        rightSection={<IconShoppingCart size={20} />}
                    >
                        اشتري الآن
                    </Button>
                </Group>
            </Card.Section>
        </Card>
    );
}